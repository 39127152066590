import React from 'react';

import { Footer } from './footer';
import { Header } from './header';

import { ContentfulSeoData } from '../generated/graphql-types';

import '@lsg/components/bundle/index.css';
import { OnPageNavigation, Logo } from '@lsg/components';
import { IOnPageNavigationItemProps } from '@lsg/components/lib/components/molecules/OnPageNavigation/OnPageNavigation';
import { slugify } from '../utils/string';
import Helmet from 'react-helmet';
import { SeoDataComponent } from '@lws/react-components';

interface Props {
  children: React.ReactNode;
  navigationNames?: string[];
  showFooter?: boolean;
  pageMetadata?: ContentfulSeoData;
}

const makeNavigationObject = (
  navigationNames: string[]
): IOnPageNavigationItemProps[] => {
  return navigationNames.map(name => {
    return {
      label: name,
      idTarget: slugify(name),
    };
  });
};

export const Layout = ({
  children,
  pageMetadata,
  navigationNames,
  showFooter = true,
}: Props) => {
  return (
    <>
      <Helmet>
        <html lang="de" />
      </Helmet>

      {pageMetadata && <SeoDataComponent data={pageMetadata} />}

      <Header siteTitle={''} />
      {navigationNames && navigationNames.length > 0 && (
        <OnPageNavigation
          logoArea={<Logo size={Logo.Sizes.SMALL} href={'/'} />}
          buttonArea={<></>}
          navigationObject={makeNavigationObject(navigationNames)}
        />
      )}
      {children}
      {showFooter && <Footer />}
    </>
  );
};

export default Layout;
