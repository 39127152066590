import React from 'react';

import { Link, Header as LSGHeader, Picture } from '@lsg/components';
import { navigate } from 'gatsby';

const logoImage = require('../images/logo-cbm.svg');

interface Props {
  siteTitle: string;
}

const navObj = [
  {
    label: 'Home',
    targetValue: '/',
  },
];

function handleHeaderNav(
  value: string,
  _name: string,
  _event: React.SyntheticEvent<HTMLElement, Event>
) {
  navigate(value);
}

export const CustomHeaderLogo = () => (
  <Link to="/">
    <Picture src={logoImage} style={{ margin: 'auto' }} />
  </Link>
);

export const Header = ({ siteTitle }: Props) => (
  <LSGHeader
    // navigationObject={navObj}
    className="lsg--header--cbm"
    segmentLabel={((<CustomHeaderLogo />) as any) as string}
    activeValue={'/'}
    onChange={handleHeaderNav}
  />
);
