import React from 'react';
import {
  Metabar as LSGMetabar,
  Link,
  Section,
  Footer as LSGFooter,
  Themes,
  ContactModule,
  Headline,
  Brandbar,
  Icon,
  Picture,
} from '@lsg/components';
import styled from 'styled-components';
const logoImage = require('../images/logo-monochrom-cbm.svg');

const Metabar = () => {
  return (
    <LSGMetabar>
      <LSGMetabar.MetaLinks>
        <Link to="/datenschutz">Datenschutz</Link>
        <Link to="/impressum">Impressum</Link>
        <Link href="https://www.commerzbank.de/portal/de/footer1/agb/agb.html">
          AGB
        </Link>
      </LSGMetabar.MetaLinks>
    </LSGMetabar>
  );
};
export function Footer({ onlyMetabar = false }) {
  return onlyMetabar ? (
    <Section>
      <MetabarStyled>
        <Metabar />
      </MetabarStyled>
    </Section>
  ) : (
    <LSGFooter
      theme={Themes.DARK}
      previousTheme={Themes.LIGHT}
      contactArea={
        <ContactModule
          phoneNumber={''}
          phoneText={
            <>
              <Headline size={Headline.Sizes?.H4}>Interesse geweckt?</Headline>
              <Headline
                size={Headline.Sizes?.H4}
                variant={Headline.Variants?.THIN}
              >
                Bewerben Sie sich jetzt!
              </Headline>
            </>
          }
        >
          <button className="lsg--iconlink lsg--icon__hover-anchor lsg--iconlink__circle">
            <span className="lsg--button-text">
              <Link
                className={'lsg--icon lsg--iconlink-svg lsg--icon__default'}
                to={'/jobs/'}
              >
                <Icon
                  name={Icon.Names.communication___envelope}
                  size={Icon.Sizes.CIRCLE}
                />
              </Link>
              <span className="lsg--iconlink-label">Bewerben</span>
            </span>
          </button>
        </ContactModule>
      }
    >
      <CustomBrandbar>
        <CustomFooterLogo />
        <p className="lsg--footer-brandbar-slogan">Wir bauen und verwalten</p>
      </CustomBrandbar>
      <Metabar />
    </LSGFooter>
  );
}

export const CustomFooterLogo = () => (
  <LogoWrapper>
    <Link to="/">
      <Picture src={logoImage} style={{ margin: 'auto' }} />
    </Link>
  </LogoWrapper>
);

const MetabarStyled = styled.div`
  .lsg--footer-metabar,
  .lsg--footer-metabar.lsg--theme__light,
  .lsg--theme__light .lsg--footer-metabar {
    border-top: none;
    a {
      color: black !important;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 157px;
  height: 32px;
`;

const CustomBrandbar = styled.div`
  /* height: 97px; */
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 638px) {
    p {
      display: none;
    }
  }
`;
